<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      header-text="Global Deployments"
      class="px-3"
    />

    <v-row>
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prevMonth"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="nextMonth"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" class="ml-3 mr-4">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn
              v-if="isVisibleToday"
              outlined
              class="elevation-0 outlined-btn px-12"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="isDayType"
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setMonth"
            >
              Month
            </v-btn>
          </v-toolbar>
        </v-sheet>

        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            event-more
            :type="type"
            @click:event="handleEventClick"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          >
            <template #event="{ event }">
              <div class="pl-1">
                <span class="v-event-summary">
                  {{ formatEventStart(event.start) }}
                  <strong>{{ event.name }}</strong>
                  <span>{{ hasSameTimeEvents(event.start) }}</span>
                </span>
              </div>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            min-width="400px"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                dark
                height="40px"
              >
                Other Events
              </v-toolbar>
              <v-list>
                <v-list-item
                  v-for="event in sameTimeEvents"
                  :key="event.campaignId"
                  :style="{ backgroundColor: event.color }"
                  class="list-event-item"
                  @click="goToCampaign({ event })"
                >
                  <v-list-item-content class="list-event-item-content">
                    {{ event.name }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";

export default {
  name: "CampaignsCalendar",
  metaInfo: {
    title: 'Global Deployments Calendar'
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      events: [],
      focus: '',
      type: 'month',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      sameTimeEvents: [],
      colors: {
        live: '#1f9f43',
        draft: '#e4a61a',
        completed: '#2b84eb',
        canceled: '#dc2929',
        scheduled: '#084eff',
        preparing: '#32612d',
      },
    };
  },
  computed: {
    isDayType() {
      return this.type === 'day';
    },
    isVisibleToday() {
      const curDate = new Date().toISOString().slice(0, 10);
      const curMonth = new Date().toISOString().slice(0, 7);
      const focusMonth = this.focus ? this.focus.slice(0, 7) : curMonth;
      if (this.type === 'month') {
        return curMonth !== focusMonth;
      }
      return this.focus !== curDate;
    }
  },
  mounted () {
    this.$refs.calendar.checkChange();
  },
  methods: {
    async getCampaignsByMonth(startDate, endDate) {
      const resp = await this.$rest.accountCampaigns.getCollection({ startDate, endDate });
      if (resp.data.length > 0) {
        this.events = resp.data.map((item) => ({
          name: `${item.name} - ${item.campaignName}`,
          start: new Date(item.startDate),
          color: this.colors[item.status],
          timed: true,
          accountId: item.accountId,
          campaignId: item.campaignId,
          campaignType: item.campaignType,
        }));
      } else {
        this.events = [];
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    setMonth() {
      this.type = 'month';
    },
    prevMonth() {
      this.$refs.calendar.prev();
    },
    nextMonth() {
      this.$refs.calendar.next();
    },
    updateRange({ start, end }) {
      this.getCampaignsByMonth(new Date(`${start.date}T00:00:00Z`), new Date(`${end.date}T23:59:59Z`));
    },
    handleEventClick ({ nativeEvent, event }) {
      const sameTimeCampaigns = this.events.filter(campaign => campaign.start.toISOString() === event.start.toISOString())
      if (this.type === 'month' || sameTimeCampaigns.length < 2) {
        this.goToCampaign({ event });
      } else {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          this.sameTimeEvents = sameTimeCampaigns;
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }
  
        if (this.selectedOpen) {
          this.selectedOpen = false
          this.sameTimeEvents = []
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
  
        nativeEvent.stopPropagation()
      }
    },
    goToCampaign({ event }) {
      if (event.campaignType === "automation") {
        this.$router.push({
          name: "AutomatedCampaign",
          params: { id: event.campaignId },
          query: { accid: event.accountId },
        });
      } else if (event.campaignType === "simple") {
        this.$router.push({
          name: "SimpleCampaign",
          params: { id: event.campaignId },
          query: { accid: event.accountId },
        });
      } else if (event.campaignType === "split-test") {
        this.$router.push({
          name: "SimpleCampaign",
          params: { id: event.campaignId },
          query: { accid: event.accountId },
        });
      }
    },
    formatEventStart(eventStart) {
      if (this.type === 'day') return '';
      const hours = new Date(eventStart).getHours();
      const minutes = new Date(eventStart).getMinutes();
      const formatHours = `${hours > 12 ? hours - 12 : hours}`;
      const formatMinutes = minutes > 0 ? `${Math.floor(minutes / 10)}${minutes % 10}` : '';
      const formatDay = `${hours > 12 ? 'PM' : 'AM'}`;
      return `${formatHours}${minutes > 0 ? ':' : ''}${formatMinutes} ${formatDay}`;
    },
    hasSameTimeEvents(eventStart) {
      if (this.type === 'month') return '';
      const eventsCount = this.events.filter(campaign => campaign.start.toISOString() === eventStart.toISOString()).length;
      return eventsCount > 1 ? `, ${eventsCount - 1} more` : '';
    }
  },
};
</script>

<style lang="scss" scoped>
.list-event-item {
  height: 32px;
  min-height: 32px;
  margin: 4px 4px;
  border-radius: 4px;
}
.list-event-item-content {
  color: #ffffff;
  font-size: 14px;
}
</style>
